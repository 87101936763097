import {
    RECEIVE_GET_ALL_CREDENTIALS,
    RECEIVE_GET_ALL_CREDENTIALS_ERROR,
    RECEIVE_UNOPENED_LEADS,
    RECEIVE_UNOPENED_LEADS_ERROR,
    RECEIVE_UPDATE_COACH,
    REQUEST_GET_ALL_CREDENTIALS,
    REQUEST_UNOPENED_LEADS,
} from "../actions";
import {
    REQUEST_SEARCH_MFR_PROVIDERS,
    RECEIVE_SEARCH_MFR_PROVIDERS,
    RECEIVE_SEARCH_MFR_PROVIDERS_ERROR,
    REQUEST_PROVIDER_PROFILE,
    RECEIVE_PROVIDER_PROFILE,
    RECEIVE_PROVIDER_PROFILE_ERROR,
    REQUEST_SEARCH_MFR_CLINICS,
    RECEIVE_SEARCH_MFR_CLINICS,
    RECEIVE_SEARCH_MFR_CLINICS_ERROR,
    REQUEST_GET_CLINIC,
    RECEIVE_GET_CLINIC,
    RECEIVE_GET_CLINIC_ERROR,
    REQUEST_SUBMIT_LEAD_CONTACT_FORM,
    RECEIVE_SUBMIT_LEAD_CONTACT_FORM,
    RECEIVE_SUBMIT_LEAD_CONTACT_FORM_ERROR,
    REQUEST_UPDATE_PROVIDER_PROFILE,
    RECEIVE_UPDATE_PROVIDER_PROFILE,
    RECEIVE_UPDATE_PROVIDER_PROFILE_ERROR,
    UPDATE_CONTACT_FORM_DATA,
    REQUEST_UPDATE_CLINIC_PROFILE,
    RECEIVE_UPDATE_CLINIC_PROFILE,
    RECEIVE_UPDATE_CLINIC_PROFILE_ERROR,
    REQUEST_CREATE_CLINIC_CREDENTIAL,
    RECEIVE_CREATE_CLINIC_CREDENTIAL,
    RECEIVE_CREATE_CLINIC_CREDENTIAL_ERROR,
    REQUEST_REMOVE_CLINIC_CREDENTIAL,
    RECEIVE_REMOVE_CLINIC_CREDENTIAL,
    RECEIVE_REMOVE_CLINIC_CREDENTIAL_ERROR,
    REQUEST_CREATE_PROVIDER_CREDENTIAL,
    RECEIVE_CREATE_PROVIDER_CREDENTIAL,
    RECEIVE_CREATE_PROVIDER_CREDENTIAL_ERROR,
    REQUEST_REMOVE_PROVIDER_CREDENTIAL,
    RECEIVE_REMOVE_PROVIDER_CREDENTIAL,
    RECEIVE_REMOVE_PROVIDER_CREDENTIAL_ERROR,
    REQUEST_UPLOAD_CLINIC_IMAGE,
    RECEIVE_UPLOAD_CLINIC_IMAGE,
    RECEIVE_UPLOAD_CLINIC_IMAGE_ERROR,
    REQUEST_REMOVE_CLINIC_IMAGE,
    RECEIVE_REMOVE_CLINIC_IMAGE,
    RECEIVE_REMOVE_CLINIC_IMAGE_ERROR,
    REQUEST_UPLOAD_PROVIDER_IMAGE,
    RECEIVE_UPLOAD_PROVIDER_IMAGE,
    RECEIVE_UPLOAD_PROVIDER_IMAGE_ERROR,
    REQUEST_SEARCH_PROVIDERS,
    RECEIVE_SEARCH_PROVIDERS,
    RECEIVE_SEARCH_PROVIDERS_ERROR,
    STORE_USER_LOCATION,
    REQUEST_CREATE_CLINIC_PROFILE,
    RECEIVE_CREATE_CLINIC_PROFILE,
    RECEIVE_CREATE_CLINIC_PROFILE_ERROR,
    REQUEST_PROVIDER_PROFILE_DATA_POINTS,
    RECEIVE_PROVIDER_PROFILE_DATA_POINTS,
    RECEIVE_PROVIDER_PROFILE_DATA_POINTS_ERROR,
    REQUEST_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS,
    RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS,
    RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS_ERROR,
    RECEIVE_GET_STATE_BLURB,
    REQUEST_GET_CITY_LIST,
    RECEIVE_GET_CITY_LIST,
    RECEIVE_GET_CITY_LIST_ERROR,
    REQUEST_CLEAR_CITY_LIST,
} from "../actions/mfr";

const mfrInitialState = {
    providers: [],
    cityList: [],
    providerProfileHistoricalDataPoints: [],
};

export function mfr(state = mfrInitialState, action) {
    switch (action.type) {
        case REQUEST_SEARCH_MFR_PROVIDERS:
            return {
                ...state,
                searchMfrProvidersIsFetching: true,
                searchMfrProvidersRequestedAt: action.requestedAt,
            };
        case RECEIVE_SEARCH_MFR_PROVIDERS:
            const { data } = action;
            const providers = JSON.parse(JSON.stringify(data.results));
            const clinics =
                providers && providers.length > 1
                    ? providers.reduce((acc, provider) => {
                          const { found_clinics } = provider;

                          if (found_clinics && found_clinics.length > 0) {
                              found_clinics.forEach((clinic) => {
                                  if (!acc) {
                                      acc = {};
                                      acc = {
                                          [clinic.id]: {
                                              ...(acc[clinic.id] || {}),
                                              ...clinic,
                                              providers: [provider],
                                          },
                                      };
                                  } else {
                                      acc = {
                                          ...acc,
                                          [clinic.id]: {
                                              ...(acc[clinic.id] ? acc[clinic.id] : clinic),
                                              providers: [
                                                  ...((acc[clinic.id] && acc[clinic.id].providers) || []),
                                                  provider,
                                              ],
                                          },
                                      };
                                  }

                                  return acc;
                              });
                          }
                          return acc;
                      }, null)
                    : providers && providers.length === 1 && providers[0].found_clinics;

            // Remove unnecessary data from clinics
            if (clinics.bio) delete clinics.bio;
            if (clinics.found_clinics) delete clinics.found_clinics;
            if (clinics.id) delete clinics.id;
            if (clinics.title) delete clinics.title;
            if (clinics.user) delete clinics.user;
            if (clinics.profile_credentials) delete clinics.profile_credentials;

            // If we have an array, make it an object so both scenarios use the same data structure
            const pins = Array.isArray(clinics)
                ? clinics.length > 0
                    ? Object.assign(...clinics.map((c) => ({ [c.id]: { ...c } })))
                    : []
                : clinics;

            return {
                ...state,
                searchMfrProvidersIsFetching: false,
                searchMfrProvidersReceivedAt: action.receivedAt,
                providerCount: data.count,
                next: data.next,
                previous: data.previous,
                providers: data,
                pins,
            };
        case RECEIVE_SEARCH_MFR_PROVIDERS_ERROR:
            return {
                ...state,
                searchMfrProvidersIsFetching: false,
                searchMfrProvidersReceivedAt: action.receivedAt,
                searchMfrProvidersError: action.error,
            };
        case REQUEST_SEARCH_PROVIDERS:
            return {
                ...state,
                searchProvidersIsFetching: true,
                searchProvidersRequestedAt: action.requestedAt,
            };
        case RECEIVE_SEARCH_PROVIDERS:
            return {
                ...state,
                searchProvidersIsFetching: false,
                searchProvidersReceivedAt: action.receivedAt,
                providerCount: action.data.count,
                providers: action.data.results,
            };
        case RECEIVE_SEARCH_PROVIDERS_ERROR:
            return {
                ...state,
                searchProvidersIsFetching: false,
                searchProvidersReceivedAt: action.receivedAt,
                searchProvidersError: action.error,
            };
        case REQUEST_PROVIDER_PROFILE:
            return {
                ...state,
                mfrProviderIsFetching: true,
                mfrProviderRequestedAt: action.requestedAt,
            };
        case RECEIVE_PROVIDER_PROFILE:
            return {
                ...state,
                mfrProviderIsFetching: false,
                mfrProviderReceivedAt: action.receivedAt,
                provider: action.data,
            };
        case RECEIVE_PROVIDER_PROFILE_ERROR:
            return {
                ...state,
                mfrProviderIsFetching: false,
                mfrProviderReceivedAt: action.receivedAt,
                mfrProviderError: action.error,
            };
        case REQUEST_SEARCH_MFR_CLINICS:
            return {
                ...state,
                searchMfrClinicIsFetching: true,
                searchMfrClinicRequestedAt: action.requestedAt,
            };
        case RECEIVE_SEARCH_MFR_CLINICS:
            return {
                ...state,
                searchMfrClinicIsFetching: false,
                searchMfrClinicReceivedAt: action.receivedAt,
                clinics: action.data.results,
                activeClinics: action.data.count,
            };
        case RECEIVE_SEARCH_MFR_CLINICS_ERROR:
            return {
                ...state,
                searchMfrClinicIsFetching: false,
                searchMfrClinicReceivedAt: action.receivedAt,
                searchMfrClinicError: action.error,
            };
        case REQUEST_GET_CLINIC:
            return {
                ...state,
                clinicIsFetching: true,
                clinicRequestedAt: action.requestedAt,
            };
        case RECEIVE_GET_CLINIC:
            return {
                ...state,
                clinicIsFetching: false,
                clinicReceivedAt: action.receivedAt,
                clinic: action.data,
            };
        case RECEIVE_GET_CLINIC_ERROR:
            return {
                ...state,
                clinicIsFetching: false,
                clinicReceivedAt: action.receivedAt,
                clinicError: action.error,
            };
        case REQUEST_GET_CITY_LIST:
            return {
                ...state,
                cityListIsFetching: true,
                cityListRequestedAt: action.requestedAt,
            };
        case RECEIVE_GET_CITY_LIST:
            return {
                ...state,
                cityListIsFetching: false,
                cityListReceivedAt: action.receivedAt,
                cityList: action.data,
            };
        case RECEIVE_GET_CITY_LIST_ERROR:
            return {
                ...state,
                cityListIsFetching: false,
                cityListReceivedAt: action.receivedAt,
                cityListError: action.error,
            };
        case REQUEST_CLEAR_CITY_LIST:
            return {
                ...state,
                cityList: [],
            };
        case REQUEST_SUBMIT_LEAD_CONTACT_FORM:
            return {
                ...state,
                leadContactFormIsFetching: true,
                leadContactFormRequestedAt: action.requestedAt,
            };
        case RECEIVE_SUBMIT_LEAD_CONTACT_FORM:
            return {
                ...state,
                leadContactFormIsFetching: false,
                leadContactFormReceivedAt: action.receivedAt,
                leadContactForm: action.data,
            };
        case RECEIVE_SUBMIT_LEAD_CONTACT_FORM_ERROR:
            return {
                ...state,
                leadContactFormIsFetching: false,
                leadContactFormReceivedAt: action.receivedAt,
                leadContactFormError: action.error,
            };
        case RECEIVE_UPDATE_COACH: {
            const { provider: currentProvider, providers: currentProviders } = { ...state };
            const updatedProvider =
                currentProviders &&
                currentProviders.length > 0 &&
                currentProviders.find((p) => p.user && p.user.id === action.data.id);

            if (updatedProvider) {
                updatedProvider.user.first_name = action.data.first_name;
                updatedProvider.user.last_name = action.data.last_name;
                updatedProvider.user.email = action.data.email;
            }

            let provider = {};
            if (currentProvider) {
                provider = {
                    ...currentProvider,
                    bio: action.data.bio,
                    user: {
                        ...currentProvider.user,
                        first_name: action.data.first_name,
                        last_name: action.data.last_name,
                    },
                };
            } else {
                provider = {
                    bio: action.data.bio,
                    user: {
                        first_name: action.data.first_name,
                        last_name: action.data.last_name,
                    },
                };
            }

            return {
                ...state,
                provider,
            };
        }
        case REQUEST_UPDATE_PROVIDER_PROFILE:
            return {
                ...state,
                updateProviderProfileIsFetching: true,
                updateProviderProfileRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_PROVIDER_PROFILE: {
            const { provider: currentProvider, providers: currentProviders } = { ...state };

            const updatedProviderInList =
                currentProviders &&
                currentProviders.length > 0 &&
                currentProviders.find((p) => p && p.id === action.data.id);

            if (updatedProviderInList) updatedProviderInList.active = action.data.active;

            const updatedProvider = {
                ...currentProvider,
                active: action.data.active,
                title: action.data.title,
                bio: action.data.bio,
                phone: action.data.phone,
                expertise_level: action.data.expertise_level,
                alt_phone: action.data.alt_phone,
                alt_website: action.data.alt_website,
                facebook: action.data.facebook,
                twitter: action.data.twitter,
                instagram: action.data.instagram,
                linkedin: action.data.linkedin,
                keywords: action.data.keywords,
                vanity_phone: action.data.vanity_phone,
            };

            return {
                ...state,
                updateProviderProfileIsFetching: false,
                updateProviderProfileReceivedAt: action.receivedAt,
                provider: updatedProvider,
            };
        }
        case RECEIVE_UPDATE_PROVIDER_PROFILE_ERROR:
            return {
                ...state,
                updateProviderProfileIsFetching: false,
                updateProviderProfileReceivedAt: action.receivedAt,
                updateProviderProfileError: action.error,
            };
        case REQUEST_CREATE_CLINIC_PROFILE:
            return {
                ...state,
                createClinicProfileIsFetching: true,
                createClinicProfileRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_CLINIC_PROFILE:
            return {
                ...state,
                createClinicProfileIsFetching: false,
                createClinicProfileReceivedAt: action.receivedAt,
            };
        case RECEIVE_CREATE_CLINIC_PROFILE_ERROR:
            return {
                ...state,
                createClinicProfileIsFetching: false,
                createClinicProfileReceivedAt: action.receivedAt,
                createClinicProfileError: action.error,
            };
        case REQUEST_UPDATE_CLINIC_PROFILE:
            return {
                ...state,
                updateClinicProfileIsFetching: true,
                updateClinicProfileRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_CLINIC_PROFILE: {
            const { clinic: currentClinic, clinics: currentClinics } = { ...state };

            const updatedClinicInList =
                currentClinics &&
                currentClinics.length > 0 &&
                currentClinics.find((clinic) => clinic.id === action.data.id);

            if (updatedClinicInList) {
                updatedClinicInList.title = action.data.title;
                updatedClinicInList.address1 = action.data.address1;
                updatedClinicInList.address2 = action.data.address2;
                updatedClinicInList.city = action.data.city;
                updatedClinicInList.state = action.data.state;
                updatedClinicInList.country = action.data.country;
                updatedClinicInList.zip = action.data.zip;
                updatedClinicInList.phone = action.data.phone;
                updatedClinicInList.email = action.data.email;
                updatedClinicInList.public = action.data.public;
                updatedClinicInList.providers = action.data.providers;
                updatedClinicInList.keywords = action.data.keywords;
                updatedClinicInList.vanity_phone = action.data.vanity_phone;
            }

            const updatedClinic = {
                ...currentClinic,
                title: action.data.title,
                address1: action.data.address1,
                address2: action.data.address2,
                city: action.data.city,
                state: action.data.state,
                zip: action.data.zip,
                phone: action.data.phone,
                alt_phone: action.data.alt_phone,
                website: action.data.website,
                facebook: action.data.facebook,
                twitter: action.data.twitter,
                instagram: action.data.instagram,
                bio: action.data.bio,
                public: action.data.public,
                url: action.data.url,
                keywords: action.data.keywords,
                vanity_phone: action.data.vanity_phone,
                providers: action.data.providers,
            };

            return {
                ...state,
                updateClinicProfileIsFetching: false,
                updateClinicProfileReceivedAt: action.receivedAt,
                clinic: updatedClinic,
            };
        }
        case RECEIVE_UPDATE_CLINIC_PROFILE_ERROR:
            return {
                ...state,
                updateClinicProfileIsFetching: false,
                updateClinicProfileReceivedAt: action.receivedAt,
                updateClinicProfileError: action.error,
            };
        case REQUEST_CREATE_CLINIC_CREDENTIAL:
            return {
                ...state,
                createClinicCredentialIsFetching: true,
                createClinicCredentialRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_CLINIC_CREDENTIAL: {
            const { clinic: currentClinic } = { ...state };
            currentClinic.clinic_credentials.push(action.data);

            return {
                ...state,
                createClinicCredentialIsFetching: false,
                createClinicCredentialReceivedAt: action.receivedAt,
                clinic: currentClinic,
            };
        }
        case RECEIVE_CREATE_CLINIC_CREDENTIAL_ERROR:
            return {
                ...state,
                createClinicCredentialIsFetching: false,
                createClinicCredentialReceivedAt: action.receivedAt,
                createClinicCredentialError: action.error,
            };
        case REQUEST_REMOVE_CLINIC_CREDENTIAL:
            return {
                ...state,
                removeClinicCredentialIsFetching: true,
                removeClinicCredentialRequestedAt: action.requestedAt,
            };
        case RECEIVE_REMOVE_CLINIC_CREDENTIAL: {
            const { clinic: currentClinic } = { ...state };
            const credentialIndex = currentClinic.clinic_credentials.findIndex(
                (credential) => credential.id === action.id
            );

            currentClinic.clinic_credentials.splice(credentialIndex, 1);

            return {
                ...state,
                removeClinicCredentialIsFetching: false,
                removeClinicCredentialReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_REMOVE_CLINIC_CREDENTIAL_ERROR:
            return {
                ...state,
                removeClinicCredentialIsFetching: false,
                removeClinicCredentialReceivedAt: action.receivedAt,
                removeClinicCredentialError: action.error,
            };
        case REQUEST_CREATE_PROVIDER_CREDENTIAL:
            return {
                ...state,
                createProviderCredentialIsFetching: true,
                createProviderCredentialRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_PROVIDER_CREDENTIAL: {
            const { provider: currentProvider } = { ...state };
            currentProvider.profile_credentials.push(action.data);

            return {
                ...state,
                createProviderCredentialIsFetching: false,
                createProviderCredentialReceivedAt: action.receivedAt,
                provider: currentProvider,
            };
        }
        case RECEIVE_CREATE_PROVIDER_CREDENTIAL_ERROR:
            return {
                ...state,
                createProviderCredentialIsFetching: false,
                createProviderCredentialReceivedAt: action.receivedAt,
                createProviderCredentialError: action.error,
            };
        case REQUEST_REMOVE_PROVIDER_CREDENTIAL:
            return {
                ...state,
                removeProviderCredentialIsFetching: true,
                removeProviderCredentialRequestedAt: action.requestedAt,
            };
        case RECEIVE_REMOVE_PROVIDER_CREDENTIAL: {
            const { provider: currentProvider } = { ...state };
            const credentialIndex = currentProvider.profile_credentials.findIndex(
                (credential) => credential.id === action.id
            );

            currentProvider.profile_credentials.splice(credentialIndex, 1);

            return {
                ...state,
                removeProviderCredentialIsFetching: false,
                removeProviderCredentialReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_REMOVE_PROVIDER_CREDENTIAL_ERROR:
            return {
                ...state,
                removeProviderCredentialIsFetching: false,
                removeProviderCredentialReceivedAt: action.receivedAt,
                removeProviderCredentialError: action.error,
            };
        case REQUEST_UPLOAD_CLINIC_IMAGE:
            return {
                ...state,
                uploadClinicImageIsFetching: true,
                uploadClinicImageRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPLOAD_CLINIC_IMAGE: {
            const { clinic: currentClinic } = { ...state };

            currentClinic.clinic_images.push(action.data);

            return {
                ...state,
                uploadClinicImageIsFetching: false,
                uploadClinicImageReceivedAt: action.receivedAt,
                clinic: currentClinic,
            };
        }
        case RECEIVE_UPLOAD_CLINIC_IMAGE_ERROR:
            return {
                ...state,
                uploadClinicImageIsFetching: false,
                uploadClinicImageReceivedAt: action.receivedAt,
                uploadClinicImageError: action.error,
            };
        case REQUEST_REMOVE_CLINIC_IMAGE:
            return {
                ...state,
                removeClinicImageIsFetching: true,
                removeClinicImageRequestedAt: action.requestedAt,
            };
        case RECEIVE_REMOVE_CLINIC_IMAGE: {
            const { clinic: currentClinic } = { ...state };
            const imageIndex = currentClinic.clinic_images.findIndex((image) => image.id === action.id);

            currentClinic.clinic_images.splice(imageIndex, 1);

            return {
                ...state,
                removeClinicImageIsFetching: false,
                removeClinicImageReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_REMOVE_CLINIC_IMAGE_ERROR:
            return {
                ...state,
                removeClinicImageIsFetching: false,
                removeClinicImageReceivedAt: action.receivedAt,
                removeClinicImageError: action.error,
            };
        case REQUEST_PROVIDER_PROFILE_DATA_POINTS:
            return {
                ...state,
                providerProfileDataPointsIsFetching: true,
                providerProfileDataPointsRequestedAt: action.requestedAt,
            };
        case RECEIVE_PROVIDER_PROFILE_DATA_POINTS:
            return {
                ...state,
                providerProfileDataPointsIsFetching: false,
                providerProfileDataPointsReceivedAt: action.receivedAt,
                providerProfileDataPoints: action.data,
            };
        case RECEIVE_PROVIDER_PROFILE_DATA_POINTS_ERROR:
            return {
                ...state,
                providerProfileDataPointsIsFetching: false,
                providerProfileDataPointsReceivedAt: action.receivedAt,
                providerProfileDataPointsError: action.error,
            };
        case REQUEST_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS:
            return {
                ...state,
                providerProfileHistoricalDataPointsIsFetching: true,
                providerProfileHistoricalDataPointsRequestedAt: action.requestedAt,
            };
        case RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS:
            return {
                ...state,
                providerProfileHistoricalDataPointsIsFetching: false,
                providerProfileHistoricalDataPointsReceivedAt: action.receivedAt,
                providerProfileHistoricalDataPoints: action.data.reverse(),
            };
        case RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS_ERROR:
            return {
                ...state,
                providerProfileHistoricalDataPointsIsFetching: false,
                providerProfileHistoricalDataPointsReceivedAt: action.receivedAt,
                providerProfileHistoricalDataPointsError: action.error,
            };
        case REQUEST_UPLOAD_PROVIDER_IMAGE:
            return {
                ...state,
                uploadProviderImageIsFetching: true,
                uploadProviderImageRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPLOAD_PROVIDER_IMAGE: {
            const { provider: currentProvider } = { ...state };

            currentProvider.photo = action.data.photo;

            return {
                ...state,
                uploadProviderImageIsFetching: false,
                uploadProviderImageReceivedAt: action.receivedAt,
                provider: currentProvider,
            };
        }
        case RECEIVE_UPLOAD_PROVIDER_IMAGE_ERROR:
            return {
                ...state,
                uploadProviderImageIsFetching: false,
                uploadProviderImageReceivedAt: action.receivedAt,
                uploadProviderImageError: action.error,
            };
        case REQUEST_GET_ALL_CREDENTIALS:
            return {
                ...state,
                getAllCredentialsIsFetching: true,
                getAllCredentialsRequestedAt: action.requestedAt,
            };
        case RECEIVE_GET_ALL_CREDENTIALS:
            return {
                ...state,
                getAllCredentialsIsFetching: false,
                getAllCredentialsReceivedAt: action.receivedAt,
                credentialsList: action.data,
            };
        case RECEIVE_GET_ALL_CREDENTIALS_ERROR:
            return {
                ...state,
                getAllCredentialsIsFetching: false,
                getAllCredentialsReceivedAt: action.receivedAt,
                getAllCredentialsError: action.error,
            };
        case REQUEST_UNOPENED_LEADS:
            return {
                ...state,
                unopenedLeadsIsFetching: true,
                unopenedLeadsRequestedAt: action.requestedAt,
            };
        case RECEIVE_UNOPENED_LEADS:
            return {
                ...state,
                unopenedLeadsIsFetching: false,
                unopenedLeadsReceivedAt: action.receivedAt,
                unopenedLeads: action.data,
            };
        case RECEIVE_UNOPENED_LEADS_ERROR:
            return {
                ...state,
                unopenedLeadsIsFetching: false,
                unopenedLeadsReceivedAt: action.receivedAt,
                unopenedLeadsError: action.error,
            };
        case RECEIVE_GET_STATE_BLURB:
            const stateMetaData = action.data && action.data.length > 0 && action.data[0];

            return {
                ...state,
                stateSEOTitle: stateMetaData.optimized_seo_title,
                stateMetaDescription: stateMetaData.meta_description,
                stateContentBlurb: stateMetaData.clarifying_content_blurb,
                statecCntentSubheadline: stateMetaData.clarifying_content_subheadline,
            };
        case STORE_USER_LOCATION:
            return {
                ...state,
                leadLocation: action.location,
            };
        case UPDATE_CONTACT_FORM_DATA:
            return {
                ...state,
                contactFormData: {
                    ...state.contactFormData,
                    ...action.data,
                },
            };
        default:
            return state;
    }
}
