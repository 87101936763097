import React, { useEffect, useRef, useState } from "react";
import { Box, Card, Grid, ListItemText, MenuItem, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import qs from "query-string";
import * as yup from "yup";

import { getAllCredentials, getStateBlurb, searchMfrProviders, storeUserLocation } from "../../app/actions";
import { PROVIDER_MAP_RESULTS_ROUTE } from "../../utils/routes";
import {
    DEFAULT_PROVIDER_PAGE_SIZE,
    STATES_LIST,
    getPublicImage,
    parseGoogleAddressComponents,
} from "../../utils/helpers";
import { useForm } from "react-hook-form";

import ButtonMain from "../button/ButtonMain";
import FormProvider from "../minimal/hook-form/FormProvider";
import RHFTextField from "../minimal/hook-form/RHFTextField";
import RHFSelect from "../minimal/hook-form/RHFSelect";
import Spinner from "../spinner/Spinner";
import SvgIconStyle from "../minimal/SvgIconStyle";
import { toast } from "../notifications/Toast";

const providerSearchSchema = yup.object().shape({});

const initialValues = {
    name: "",
    address: "",
    clinic: "",
    provider_type: [],
};

export default function AdvanceSearch() {
    const dispatch = useDispatch();
    const history = useHistory();
    const mfr = useSelector(({ mfr }) => mfr);
    const autocompleteRef = useRef(null);
    const [addressComponents, setAddressComponents] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);

    useEffect(() => {
        function init() {
            reset(initialValues);

            const options = {};
            autocompleteRef.current = new window.google.maps.places.Autocomplete(
                document.getElementById("google-maps-autocomplete"),
                options
            );

            autocompleteRef.current.setFields(["address_components", "formatted_address"]);

            autocompleteRef.current.addListener("place_changed", handlePlaceChange);

            dispatch(getAllCredentials());
        }

        init();
    }, []); // eslint-disable-line

    const methods = useForm({
        resolver: yupResolver(providerSearchSchema),
        initialValues,
    });

    const {
        getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { dirtyFields },
    } = methods;

    const handlePlaceChange = () => {
        const places = autocompleteRef.current.getPlace();
        setValue("address", places.formatted_address);

        if (places.address_components) {
            setAddressComponents(parseGoogleAddressComponents(places.address_components));
        } else {
            setValue("address", places.name);
        }
    };

    const requestLocation = () => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    };

    const successCallback = (position) => {
        const geocoder = new window.google.maps.Geocoder();

        const location = { lat: position.coords.latitude, lng: position.coords.longitude };

        geocoder.geocode({ location }).then(async (response) => {
            if (response.results[0]) {
                const address = parseGoogleAddressComponents(response.results[0].address_components);
                const state = STATES_LIST.find((s) => s.label === address.administrative_area_level_1).value;
                const params = {
                    country: address.country,
                    clinic_state: state,
                };
                if (address.locality) params.clinic_address = address.locality;

                history.push(
                    `${PROVIDER_MAP_RESULTS_ROUTE}${
                        state &&
                        `${state && `?state=${state}`}${address.locality ? `&city=${address.locality}` : ""}` +
                            "&use_location=true"
                    }`
                );
            } else {
                toast.error("We couldn't find any providers near you");
            }
        });
    };

    const errorCallback = (error) => {
        if (error.code === 1) {
            setErrorMessage("You must allow location services to use this feature");
        }
    };

    const submitProviderSearch = async () => {
        const { address, name, clinic, provider_type } = getValues();
        const params = {};
        let state = "";

        if (name) params.name = name;
        if (clinic) params.clinic_title = clinic;
        if (provider_type.length > 0) {
            const credentials = provider_type;
            params.credentials = credentials;
        }

        if (addressComponents.country) params.country = addressComponents.country;
        if (addressComponents.administrative_area_level_1) {
            state = STATES_LIST.find((s) => s.label === addressComponents.administrative_area_level_1);
            params.clinic_state = state ? state.value : addressComponents.administrative_area_level_1;
        }
        if (addressComponents.locality) {
            params.clinic_address = addressComponents.locality;
        } else if (address && address.length > 0 && addressComponents && addressComponents.length === 0) {
            params.clinic_address = address;
        }

        if (params.clinic_state) dispatch(getStateBlurb(params.clinic_state));

        if (params.clinic_address && params.clinic_state)
            dispatch(storeUserLocation({ state: params.clinic_state, city: params.clinic_address }));
        const result = await dispatch(
            searchMfrProviders({ ...params, page_size: DEFAULT_PROVIDER_PAGE_SIZE, page: 1 })
        );
        const { error } = result;

        if (error) {
            toast.error("There was an error searching for providers");
        } else {
            const searchParams = {};
            if (state) searchParams.state = state ? state.value : addressComponents.administrative_area_level_1;
            if (params.clinic_address) searchParams.city = params.clinic_address;
            if (params.country) searchParams.country = params.country;
            if (name) searchParams.name = name;
            if (clinic) searchParams.clinic = clinic;
            if (provider_type) searchParams.type = provider_type;

            history.push(`${PROVIDER_MAP_RESULTS_ROUTE}${`?${qs.stringify(searchParams)}`}`);
        }
    };

    return (
        <>
            {mfr.searchMfrProvidersIsFetching ? (
                <Box
                    sx={{
                        width: "100%",
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner />
                </Box>
            ) : (
                <Card sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(submitProviderSearch)}>
                        <Stack sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                            <Typography variant="h3">Search for an MFR Therapist</Typography>
                        </Stack>
                        <Stack sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={10}>
                                    <RHFTextField
                                        id="google-maps-autocomplete"
                                        name="address"
                                        placeholder="Search by Country, state, city, or zip"
                                        label="Search by Country, state, city, or zip"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Stack
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="end"
                                        height="100%"
                                        minWidth="155px"
                                    >
                                        <ButtonMain outlined fullHeight fullWidth onClick={requestLocation}>
                                            <SvgIconStyle src={getPublicImage("ic_use_location.svg")} sx={{ mr: 1 }} />
                                            Find nearby
                                        </ButtonMain>
                                    </Stack>
                                </Grid>
                                {errorMessage && errorMessage.length > 0 && (
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                        <Typography variant="body2" sx={{ color: "error.main" }}>
                                            {errorMessage}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <RHFTextField name="name" label="Provider Name" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField name="clinic" label="Clinic Name" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFSelect
                                        name="provider_type"
                                        label="Credentials"
                                        defaultValue=""
                                        SelectProps={{ native: false, sx: { textTransform: "capitalize" } }}
                                        disabled={mfr && mfr.credentialsList && mfr.credentialsList.length === 0}
                                    >
                                        {mfr &&
                                            mfr.credentialsList &&
                                            mfr.credentialsList.length > 0 &&
                                            mfr.credentialsList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                    </RHFSelect>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack sx={{ mt: 2, alignItems: "end", justifyContent: "end", flex: "1 1 auto !important" }}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                className="text-right"
                                disabled={Object.keys(dirtyFields).length === 0}
                            >
                                Search for Therapist
                            </LoadingButton>
                        </Stack>
                    </FormProvider>
                </Card>
            )}
        </>
    );
}
