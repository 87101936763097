import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Card, Stack, Typography, useTheme } from "@mui/material";

import { STATES_LIST, getPublicImage, parseGoogleAddressComponents } from "../../utils/helpers";
import { PROVIDER_MAP_RESULTS_ROUTE } from "../../utils/routes";
import { searchMfrProviders } from "../../app/actions";

import Image from "../image/Image";
import { LoadingButton } from "@mui/lab";
import { toast } from "../notifications/Toast";

export default function MapSearch() {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const [errorMessage, setErrorMessage] = useState(null);

    const requestLocation = () => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    };

    const successCallback = (position) => {
        const geocoder = new window.google.maps.Geocoder();

        const location = { lat: position.coords.latitude, lng: position.coords.longitude };

        geocoder.geocode({ location }).then(async (response) => {
            if (response.results[0]) {
                const address = parseGoogleAddressComponents(response.results[0].address_components);

                const state = STATES_LIST.find((s) => s.label === address.administrative_area_level_1).value;
                const params = {
                    country: address.country,
                    clinic_state: state,
                    clinic_address: address.locality,
                };

                const result = await dispatch(searchMfrProviders(params));
                const { error } = result;

                if (error) {
                    toast.error("There was an error searching for providers");
                } else {
                    history.push(
                        `${PROVIDER_MAP_RESULTS_ROUTE}${
                            address.administrative_area_level_1 && `?state=${state}&city=${address.locality}`
                        }`
                    );
                }
            } else {
                toast.error("We couldn't find any providers near you");
            }
        });
    };

    const errorCallback = (error) => {
        if (error.code === 1) {
            setErrorMessage("You must allow location services to use this feature");
        }
    };

    return (
        <Card sx={{ p: 3, height: "100%", display: "flex", flexDirection: { xs: "column", xl: "row" } }}>
            <Stack sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: "start", mr: 3, minWidth: "75px" }}>
                    <Image src={getPublicImage("ic_map_search.png")} alt="search by map" />
                </Box>
                <Stack>
                    <Typography variant="h3">Search by Map</Typography>
                    <Typography variant="body2">
                        Find a therapist by searching the map by country, state, and zip code
                    </Typography>
                    <Typography variant="caption" sx={{ color: theme.palette.grey[500] }}>
                        Clicking "Find Near Me" will ask your permission to check your current location.
                    </Typography>
                </Stack>
            </Stack>
            <Stack sx={{ mt: 2, alignItems: "end", justifyContent: "end", flex: "1 1 auto !important" }}>
                <LoadingButton type="submit" variant="contained" className="text-right" onClick={requestLocation}>
                    Find near me
                </LoadingButton>
                {errorMessage && (
                    <Typography variant="body2" sx={{ color: "error.main" }}>
                        {errorMessage}
                    </Typography>
                )}
            </Stack>
        </Card>
    );
}
