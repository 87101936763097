import { gen_error, gen_received, gen_requested } from "./generators";
import { axiosDelete, get, patch, post } from "../../utils/network";
import { providerProfileFragment, providerSearchFragment } from "../fragments/coaches";

export const REQUEST_SEARCH_MFR_PROVIDERS = "REQUEST_SEARCH_MFR_PROVIDERS";
export const RECEIVE_SEARCH_MFR_PROVIDERS = "RECEIVE_SEARCH_MFR_PROVIDERS";
export const RECEIVE_SEARCH_MFR_PROVIDERS_ERROR = "RECEIVE_SEARCH_MFR_PROVIDERS_ERROR";
export const searchMfrProviders = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SEARCH_MFR_PROVIDERS));

        const result = await get("/api/v4/provider_profiles/search/", {
            params: { ...options, ...providerSearchFragment },
        });

        return dispatch(gen_received(RECEIVE_SEARCH_MFR_PROVIDERS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SEARCH_MFR_PROVIDERS_ERROR, error));
    }
};

export const REQUEST_SEARCH_PROVIDERS = "REQUEST_SEARCH_PROVIDERS";
export const RECEIVE_SEARCH_PROVIDERS = "RECEIVE_SEARCH_PROVIDERS";
export const RECEIVE_SEARCH_PROVIDERS_ERROR = "RECEIVE_SEARCH_PROVIDERS_ERROR";
export const searchProviders = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SEARCH_PROVIDERS));

        const result = await get("/api/v4/provider_profiles/", {
            params: { ...options, ...providerSearchFragment },
        });

        return dispatch(gen_received(RECEIVE_SEARCH_PROVIDERS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SEARCH_PROVIDERS_ERROR, error));
    }
};

export const REQUEST_PROVIDER_PROFILE = "REQUEST_PROVIDER_PROFILE";
export const RECEIVE_PROVIDER_PROFILE = "RECEIVE_PROVIDER_PROFILE";
export const RECEIVE_PROVIDER_PROFILE_ERROR = "RECEIVE_PROVIDER_PROFILE_ERROR";
export const getMfrProvider = (id, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PROVIDER_PROFILE));

        const result = await get(`/api/v4/provider_profiles/${id}/`, {
            params: { ...options, ...providerProfileFragment },
        });

        return dispatch(gen_received(RECEIVE_PROVIDER_PROFILE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PROVIDER_PROFILE_ERROR, error));
    }
};

export const REQUEST_SEARCH_MFR_CLINICS = "REQUEST_SEARCH_MFR_CLINICS";
export const RECEIVE_SEARCH_MFR_CLINICS = "RECEIVE_SEARCH_MFR_CLINICS";
export const RECEIVE_SEARCH_MFR_CLINICS_ERROR = "RECEIVE_SEARCH_MFR_CLINICS_ERROR";
export const searchClinics = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SEARCH_MFR_CLINICS));

        const result = await get("/api/v5/clinics/", { params });

        return dispatch(gen_received(RECEIVE_SEARCH_MFR_CLINICS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SEARCH_MFR_CLINICS_ERROR, error));
    }
};

export const REQUEST_GET_CLINIC = "REQUEST_GET_CLINIC";
export const RECEIVE_GET_CLINIC = "RECEIVE_GET_CLINIC";
export const RECEIVE_GET_CLINIC_ERROR = "RECEIVE_GET_CLINIC_ERROR";
export const getClinic = (id, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_CLINIC));

        const result = await get(`/api/v5/clinics/${id}/`, { params });

        return dispatch(gen_received(RECEIVE_GET_CLINIC, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_CLINIC_ERROR, error));
    }
};

export const REQUEST_GET_STATE_BLURB = "REQUEST_GET_STATE_BLURB";
export const RECEIVE_GET_STATE_BLURB = "RECEIVE_GET_STATE_BLURB";
export const RECEIVE_GET_STATE_BLURB_ERROR = "RECEIVE_GET_STATE_BLURB_ERROR";
export const getStateBlurb = (state) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_STATE_BLURB));

        const result = await get(`/api/v5/states/?abbrev=${state}`, { params: { page_size: 0 } });

        return dispatch(gen_received(RECEIVE_GET_STATE_BLURB, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_STATE_BLURB_ERROR, error));
    }
};

export const REQUEST_GET_CITY_LIST = "REQUEST_GET_CITY_LIST";
export const RECEIVE_GET_CITY_LIST = "RECEIVE_GET_CITY_LIST";
export const RECEIVE_GET_CITY_LIST_ERROR = "RECEIVE_GET_CITY_LIST_ERROR";
export const getCityList = (state) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_CITY_LIST));

        const result = await get(`/api/v5/cities/?name__icontains=${state}`, { params: { page_size: 0 } });

        return dispatch(gen_received(RECEIVE_GET_CITY_LIST, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_CITY_LIST_ERROR, error));
    }
};

export const REQUEST_CLEAR_CITY_LIST = "REQUEST_CLEAR_CITY_LIST";
export const clearCityList = () => (dispatch) => {
    return dispatch({
        type: REQUEST_CLEAR_CITY_LIST,
    });
};

export const REQUEST_SUBMIT_LEAD_CONTACT_FORM = "REQUEST_SUBMIT_LEAD_CONTACT_FORM";
export const RECEIVE_SUBMIT_LEAD_CONTACT_FORM = "RECEIVE_SUBMIT_LEAD_CONTACT_FORM";
export const RECEIVE_SUBMIT_LEAD_CONTACT_FORM_ERROR = "RECEIVE_SUBMIT_LEAD_CONTACT_FORM_ERROR";
export const submitLeadContactForm = (body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SUBMIT_LEAD_CONTACT_FORM));

        const result = await post("/api/v4/leads/contact_form/", body);

        return dispatch(gen_received(RECEIVE_SUBMIT_LEAD_CONTACT_FORM, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SUBMIT_LEAD_CONTACT_FORM_ERROR, error));
    }
};

export const REQUEST_UPDATE_PROVIDER_PROFILE = "REQUEST_UPDATE_PROVIDER_PROFILE";
export const RECEIVE_UPDATE_PROVIDER_PROFILE = "RECEIVE_UPDATE_PROVIDER_PROFILE";
export const RECEIVE_UPDATE_PROVIDER_PROFILE_ERROR = "RECEIVE_UPDATE_PROVIDER_PROFILE_ERROR";
export const updateProviderProfile = (id, body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_PROVIDER_PROFILE));

        const result = await patch(`/api/v4/provider_profiles/${id}/`, body, { params });

        return dispatch(gen_received(RECEIVE_UPDATE_PROVIDER_PROFILE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_PROVIDER_PROFILE_ERROR, error));
    }
};

export const REQUEST_CREATE_CLINIC_PROFILE = "REQUEST_CREATE_CLINIC_PROFILE";
export const RECEIVE_CREATE_CLINIC_PROFILE = "RECEIVE_CREATE_CLINIC_PROFILE";
export const RECEIVE_CREATE_CLINIC_PROFILE_ERROR = "RECEIVE_CREATE_CLINIC_PROFILE_ERROR";
export const createClinicProfile = (body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_CLINIC_PROFILE));

        const result = await post("/api/v5/clinics/", body, params);

        return dispatch(gen_received(RECEIVE_CREATE_CLINIC_PROFILE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_CLINIC_PROFILE_ERROR, error));
    }
};

export const REQUEST_UPDATE_CLINIC_PROFILE = "REQUEST_UPDATE_CLINIC_PROFILE";
export const RECEIVE_UPDATE_CLINIC_PROFILE = "RECEIVE_UPDATE_CLINIC_PROFILE";
export const RECEIVE_UPDATE_CLINIC_PROFILE_ERROR = "RECEIVE_UPDATE_CLINIC_PROFILE_ERROR";
export const updateClinicProfile = (id, body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_CLINIC_PROFILE));

        const result = await patch(`/api/v5/clinics/${id}/`, body, { params });

        return dispatch(gen_received(RECEIVE_UPDATE_CLINIC_PROFILE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_CLINIC_PROFILE_ERROR, error));
    }
};

export const REQUEST_CREATE_CLINIC_CREDENTIAL = "REQUEST_CREATE_CLINIC_CREDENTIAL";
export const RECEIVE_CREATE_CLINIC_CREDENTIAL = "RECEIVE_CREATE_CLINIC_CREDENTIAL";
export const RECEIVE_CREATE_CLINIC_CREDENTIAL_ERROR = "RECEIVE_CREATE_CLINIC_CREDENTIAL_ERROR";
export const createClinicCredential = (body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_CLINIC_CREDENTIAL));

        const result = await post("/api/v5/clinic_credentials/", body, params);

        return dispatch(gen_received(RECEIVE_CREATE_CLINIC_CREDENTIAL, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_CLINIC_CREDENTIAL_ERROR, error));
    }
};

export const REQUEST_REMOVE_CLINIC_CREDENTIAL = "REQUEST_REMOVE_CLINIC_CREDENTIAL";
export const RECEIVE_REMOVE_CLINIC_CREDENTIAL = "RECEIVE_REMOVE_CLINIC_CREDENTIAL";
export const RECEIVE_REMOVE_CLINIC_CREDENTIAL_ERROR = "RECEIVE_REMOVE_CLINIC_CREDENTIAL_ERROR";
export const removeClinicCredential = (id, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_CLINIC_CREDENTIAL));

        await axiosDelete(`/api/v5/clinic_credentials/${id}/`, params);

        return dispatch({ type: RECEIVE_REMOVE_CLINIC_CREDENTIAL, id });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_CLINIC_CREDENTIAL_ERROR, error));
    }
};

export const REQUEST_CREATE_PROVIDER_CREDENTIAL = "REQUEST_CREATE_PROVIDER_CREDENTIAL";
export const RECEIVE_CREATE_PROVIDER_CREDENTIAL = "RECEIVE_CREATE_PROVIDER_CREDENTIAL";
export const RECEIVE_CREATE_PROVIDER_CREDENTIAL_ERROR = "RECEIVE_CREATE_PROVIDER_CREDENTIAL_ERROR";
export const createProviderCredential = (body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_PROVIDER_CREDENTIAL));

        const result = await post("/api/v5/profile_credentials/", body, params);

        return dispatch(gen_received(RECEIVE_CREATE_PROVIDER_CREDENTIAL, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_PROVIDER_CREDENTIAL_ERROR, error));
    }
};

export const REQUEST_REMOVE_PROVIDER_CREDENTIAL = "REQUEST_REMOVE_PROVIDER_CREDENTIAL";
export const RECEIVE_REMOVE_PROVIDER_CREDENTIAL = "RECEIVE_REMOVE_PROVIDER_CREDENTIAL";
export const RECEIVE_REMOVE_PROVIDER_CREDENTIAL_ERROR = "RECEIVE_REMOVE_PROVIDER_CREDENTIAL_ERROR";
export const removeProviderCredential = (id, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_PROVIDER_CREDENTIAL));

        await axiosDelete(`/api/v5/profile_credentials/${id}/`, params);

        return dispatch({ type: RECEIVE_REMOVE_PROVIDER_CREDENTIAL, id });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_PROVIDER_CREDENTIAL_ERROR, error));
    }
};

export const REQUEST_UPLOAD_CLINIC_IMAGE = "REQUEST_UPLOAD_CLINIC_IMAGE";
export const RECEIVE_UPLOAD_CLINIC_IMAGE = "RECEIVE_UPLOAD_CLINIC_IMAGE";
export const RECEIVE_UPLOAD_CLINIC_IMAGE_ERROR = "RECEIVE_UPLOAD_CLINIC_IMAGE_ERROR";
export const uploadClinicImage = (body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPLOAD_CLINIC_IMAGE));

        const result = await post(`/api/v5/clinic_images/`, body, {
            headers: { "Content-type": "multipart/form-data" },
        });

        return dispatch(gen_received(RECEIVE_UPLOAD_CLINIC_IMAGE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPLOAD_CLINIC_IMAGE_ERROR, error));
    }
};

export const REQUEST_REMOVE_CLINIC_IMAGE = "REQUEST_REMOVE_CLINIC_IMAGE";
export const RECEIVE_REMOVE_CLINIC_IMAGE = "RECEIVE_REMOVE_CLINIC_IMAGE";
export const RECEIVE_REMOVE_CLINIC_IMAGE_ERROR = "RECEIVE_REMOVE_CLINIC_IMAGE_ERROR";
export const removeClinicImage = (id, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_CLINIC_IMAGE));

        await axiosDelete(`/api/v5/clinic_images/${id}/`, params);

        return dispatch({ type: RECEIVE_REMOVE_CLINIC_IMAGE, id });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_CLINIC_IMAGE_ERROR, error));
    }
};

export const REQUEST_UPLOAD_PROVIDER_IMAGE = "REQUEST_UPLOAD_PROVIDER_IMAGE";
export const RECEIVE_UPLOAD_PROVIDER_IMAGE = "RECEIVE_UPLOAD_PROVIDER_IMAGE";
export const RECEIVE_UPLOAD_PROVIDER_IMAGE_ERROR = "RECEIVE_UPLOAD_PROVIDER_IMAGE_ERROR";
export const uploadProviderImage = (providerId, body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPLOAD_PROVIDER_IMAGE));

        const result = await patch(`/api/v4/provider_profiles/${providerId}/`, body, {
            headers: { "Content-type": "multipart/form-data" },
        });

        return dispatch(gen_received(RECEIVE_UPLOAD_PROVIDER_IMAGE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPLOAD_PROVIDER_IMAGE_ERROR, error));
    }
};

export const REQUEST_SEND_PROVIDER_INVITE = "REQUEST_SEND_PROVIDER_INVITE";
export const RECEIVE_SEND_PROVIDER_INVITE = "RECEIVE_SEND_PROVIDER_INVITE";
export const RECEIVE_SEND_PROVIDER_INVITE_ERROR = "RECEIVE_SEND_PROVIDER_INVITE_ERROR";
export const sendProviderInvite = (body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SEND_PROVIDER_INVITE));

        const result = await post("/api/v4/invitations/", body, { params: { expand: "user" } });

        return dispatch(gen_received(RECEIVE_SEND_PROVIDER_INVITE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SEND_PROVIDER_INVITE_ERROR, error));
    }
};

export const REQUEST_CREATE_EMAIL = "REQUEST_CREATE_EMAIL";
export const RECEIVE_CREATE_EMAIL = "RECEIVE_CREATE_EMAIL";
export const RECEIVE_CREATE_EMAIL_ERROR = "RECEIVE_CREATE_EMAIL_ERROR";
export const createEmail = (body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_EMAIL));

        const result = await post("/api/v4/email_messages_leadreply/", body);

        return dispatch(gen_received(RECEIVE_CREATE_EMAIL, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_EMAIL_ERROR, error));
    }
};

export const REQUEST_SEND_EMAIL = "REQUEST_SEND_EMAIL";
export const RECEIVE_SEND_EMAIL = "RECEIVE_SEND_EMAIL";
export const RECEIVE_SEND_EMAIL_ERROR = "RECEIVE_SEND_EMAIL_ERROR";
export const sendEmail = (emailId, clienId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SEND_EMAIL));

        const result = await get(`/api/v4/email_messages/${emailId}/send/`);

        return dispatch({ type: RECEIVE_SEND_EMAIL, data: result.data, receivedAt: Date.now(), id: clienId });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SEND_EMAIL_ERROR, error));
    }
};

export const REQUEST_MARK_MESSAGE_SENT = "REQUEST_MARK_MESSAGE_SENT";
export const RECEIVE_MARK_MESSAGE_SENT = "RECEIVE_MARK_MESSAGE_SENT";
export const RECEIVE_MARK_MESSAGE_SENT_ERROR = "RECEIVE_MARK_MESSAGE_SENT_ERROR";
export const markMessageSent = (leadId, messageId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_MARK_MESSAGE_SENT));

        const result = await get(`/api/v4/leads/${leadId}/reply_sent/?id=${messageId}`);

        return dispatch(gen_received(RECEIVE_MARK_MESSAGE_SENT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_MARK_MESSAGE_SENT_ERROR, error));
    }
};

export const REQUEST_SEND_SES_VERIFICATION = "REQUEST_SEND_SES_VERIFICATION";
export const RECIEVE_SEND_SES_VERIFICATION = "RECIEVE_SEND_SES_VERIFICATION";
export const RECIEVE_SEND_SES_VERIFICATION_ERROR = "RECIEVE_SEND_SES_VERIFICATION_ERROR";
export const sendSESVerification = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SEND_SES_VERIFICATION));

        const result = await get(`/api/v4/ses/create_aws_identity/`);

        return dispatch(gen_received(RECIEVE_SEND_SES_VERIFICATION, result));
    } catch (error) {
        return dispatch(gen_error(RECIEVE_SEND_SES_VERIFICATION_ERROR, error));
    }
};

export const REQUEST_RESEND_SES_VERIFICATION = "REQUEST_RESEND_SES_VERIFICATION";
export const RECIEVE_RESEND_SES_VERIFICATION = "RECIEVE_RESEND_SES_VERIFICATION";
export const RECIEVE_RESEND_SES_VERIFICATION_ERROR = "RECIEVE_RESEND_SES_VERIFICATION_ERROR";
export const resendSESVerification = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_RESEND_SES_VERIFICATION));

        const result = await get(`/api/v4/ses/resend_aws_verification_link/`);

        return dispatch(gen_received(RECIEVE_RESEND_SES_VERIFICATION, result));
    } catch (error) {
        return dispatch(gen_error(RECIEVE_RESEND_SES_VERIFICATION_ERROR, error));
    }
};

export const REQUEST_VALIDATE_SES_VERIFICATION = "REQUEST_VALIDATE_SES_VERIFICATION";
export const RECIEVE_VALIDATE_SES_VERIFICATION = "RECIEVE_VALIDATE_SES_VERIFICATION";
export const RECIEVE_VALIDATE_SES_VERIFICATION_ERROR = "RECIEVE_VALIDATE_SES_VERIFICATION_ERROR";
export const validateSESVerification = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_VALIDATE_SES_VERIFICATION));

        const result = await get(`/api/v4/ses/check_aws_verification/`);

        return dispatch(gen_received(RECIEVE_VALIDATE_SES_VERIFICATION, result));
    } catch (error) {
        return dispatch(gen_error(RECIEVE_VALIDATE_SES_VERIFICATION_ERROR, error));
    }
};

export const REQUEST_CREATE_CLINIC_SHARE_DATA_POINT = "REQUEST_CREATE_CLINIC_SHARE_DATA_POINT";
export const RECEIVE_CREATE_CLINIC_SHARE_DATA_POINT = "RECEIVE_CREATE_CLINIC_SHARE_DATA_POINT";
export const RECEIVE_CREATE_CLINIC_SHARE_DATA_POINT_ERROR = "RECEIVE_CREATE_CLINIC_SHARE_DATA_POINT_ERROR";
export const createClinicShareDataPoint = (id, type) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_CLINIC_SHARE_DATA_POINT));

        const result = await get(`/api/v5/clinics/${id}/share/?type=${type}`);

        return dispatch(gen_received(RECEIVE_CREATE_CLINIC_SHARE_DATA_POINT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_CLINIC_SHARE_DATA_POINT_ERROR, error));
    }
};

export const REQUEST_PROVIDER_PROFILE_DATA_POINTS = "REQUEST_PROVIDER_PROFILE_DATA_POINTS";
export const RECEIVE_PROVIDER_PROFILE_DATA_POINTS = "RECEIVE_PROVIDER_PROFILE_DATA_POINTS";
export const RECEIVE_PROVIDER_PROFILE_DATA_POINTS_ERROR = "RECEIVE_PROVIDER_PROFILE_DATA_POINTS_ERROR";
export const getProviderProfileDataPoints = (id) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PROVIDER_PROFILE_DATA_POINTS));

        const result = await get(`/api/v4/data_points/providerprofile/calculate_profile_datapoints/`);

        return dispatch(gen_received(RECEIVE_PROVIDER_PROFILE_DATA_POINTS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PROVIDER_PROFILE_DATA_POINTS_ERROR, error));
    }
};
export const REQUEST_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS = "REQUEST_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS";
export const RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS = "RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS";
export const RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS_ERROR =
    "RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS_ERROR";
export const getProviderProfileHistoricalDataPoints = (id) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS));

        const result = await get(`/api/v4/data_points/providerprofile/calculate_profile_datapoints_last12months/`);

        return dispatch(gen_received(RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PROVIDER_PROFILE_HISTORICAL_DATA_POINTS_ERROR, error));
    }
};

export const REQUEST_CREATE_PHONE_DATA_POINT = "REQUEST_CREATE_PHONE_DATA_POINT";
export const RECEIVE_CREATE_PHONE_DATA_POINT = "RECEIVE_CREATE_PHONE_DATA_POINT";
export const RECEIVE_CREATE_PHONE_DATA_POINT_ERROR = "RECEIVE_CREATE_PHONE_DATA_POINT_ERROR";
export const createPhoneDataPoint = (id) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_PHONE_DATA_POINT));

        const result = await get(`/api/v4/provider_profiles/${id}/show_phone/`);

        return dispatch(gen_received(RECEIVE_CREATE_PHONE_DATA_POINT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_PHONE_DATA_POINT_ERROR, error));
    }
};

export const REQUEST_CREATE_PROFILE_SHARE_DATA_POINT = "REQUEST_CREATE_PROFILE_SHARE_DATA_POINT";
export const RECEIVE_CREATE_PROFILE_SHARE_DATA_POINT = "RECEIVE_CREATE_PROFILE_SHARE_DATA_POINT";
export const RECEIVE_CREATE_PROFILE_SHARE_DATA_POINT_ERROR = "RECEIVE_CREATE_PROFILE_SHARE_DATA_POINT_ERROR";
export const createProfileShareDataPoint = (id, type) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_PROFILE_SHARE_DATA_POINT));

        const result = await get(`/api/v4/provider_profiles/${id}/share/?type=${type}`);

        return dispatch(gen_received(RECEIVE_CREATE_PROFILE_SHARE_DATA_POINT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_PROFILE_SHARE_DATA_POINT_ERROR, error));
    }
};

export const REQUEST_GET_ALL_CREDENTIALS = "REQUEST_GET_ALL_CREDENTIALS";
export const RECEIVE_GET_ALL_CREDENTIALS = "RECEIVE_GET_ALL_CREDENTIALS";
export const RECEIVE_GET_ALL_CREDENTIALS_ERROR = "RECEIVE_GET_ALL_CREDENTIALS_ERROR";
export const getAllCredentials = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_ALL_CREDENTIALS));

        const result = await get("/api/v5/profile_credentials/credentials_list/");

        return dispatch(gen_received(RECEIVE_GET_ALL_CREDENTIALS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_ALL_CREDENTIALS_ERROR, error));
    }
};

export const REQUEST_UNOPENED_LEADS = "REQUEST_UNOPENED_LEADS";
export const RECEIVE_UNOPENED_LEADS = "RECEIVE_UNOPENED_LEADS";
export const RECEIVE_UNOPENED_LEADS_ERROR = "RECEIVE_UNOPENED_LEADS_ERROR";
export const getUnopenedLeads = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UNOPENED_LEADS));

        const result = await get("/api/v4/data_points/providerprofile/unopened_leads/");

        return dispatch(gen_received(RECEIVE_UNOPENED_LEADS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UNOPENED_LEADS_ERROR, error));
    }
};

export const REQUEST_ARCHIVE_LEAD = "REQUEST_ARCHIVE_LEAD";
export const RECEIVE_ARCHIVE_LEAD = "RECEIVE_ARCHIVE_LEAD";
export const RECEIVE_ARCHIVE_LEAD_ERROR = "RECEIVE_ARCHIVE_LEAD_ERROR";
export const archiveLead = (clientId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ARCHIVE_LEAD));

        const result = await patch(`/api/v4/leads/${clientId}/`, { archived: true });

        return dispatch(gen_received(RECEIVE_ARCHIVE_LEAD, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ARCHIVE_LEAD_ERROR, error));
    }
};

export const STORE_USER_LOCATION = "STORE_USER_LOCATION";
export const storeUserLocation = (location) => (dispatch) => {
    return dispatch({
        type: STORE_USER_LOCATION,
        location,
    });
};

export const UPDATE_CONTACT_FORM_DATA = "UPDATE_CONTACT_FORM_DATA";
export const updateContactFormData = (data) => (dispatch) => {
    return dispatch({
        type: UPDATE_CONTACT_FORM_DATA,
        data,
    });
};

export const UPDATE_SUBSCRIPTION_SEATS_COUNT = "UPDATE_SUBSCRIPTION_SEATS_COUNT";
export const updateSubscriptionSeatsCount = () => (dispatch) => {
    return dispatch({
        type: UPDATE_SUBSCRIPTION_SEATS_COUNT,
    });
};

export const UPDATE_SUBSCRIPTION_CLINIC_SEATS_COUNT = "UPDATE_SUBSCRIPTION_CLINIC_SEATS_COUNT";
export const updateSubscriptionClinicSeatsCount = () => (dispatch) => {
    return dispatch({
        type: UPDATE_SUBSCRIPTION_CLINIC_SEATS_COUNT,
    });
};

export const CLEAR_SUBSCRIPTION_STATUS = "CLEAR_SUBSCRIPTION_STATUS";
export const clearSubscriptionStatus = () => (dispatch) => {
    return dispatch({
        type: CLEAR_SUBSCRIPTION_STATUS,
    });
};
